.App {
  text-align: center;
  background-color: #f5f5f7;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.selected-icon .cls-1 {
  fill: #0071e2 !important;
}

.unselected-icon .cls-1 {
  fill: #D3D3D3 !important;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
    background-size: 200% 200%;
  }
  25% {
    background-position: 100% 50%;
    background-size: 250% 250%;
  }
  50% {
    background-position: 50% 100%;
    background-size: 300% 300%;
  }
  75% {
    background-position: 0% 100%;
    background-size: 250% 250%;
  }
  100% {
    background-position: 0% 50%;
    background-size: 200% 200%;
  }
}

.gradient-background {
  background: linear-gradient(
    -45deg,
    rgba(255, 228, 196, 0.6),
    rgba(230, 230, 250, 0.6),
    rgba(240, 255, 240, 0.6),
    rgba(255, 240, 245, 0.6),
    rgba(255, 228, 196, 0.6)
  ) !important;
  background-size: 400% 400% !important;
  animation: gradientAnimation 30s ease-in-out infinite;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: background 1s ease-in-out;
}

.gradient-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(20px);
  opacity: 0.7;
  animation: gradientAnimation 40s ease-in-out infinite reverse;
}

.home-container {
  position: relative;
  min-height: 100vh;
}

.home-container > *:not(.gradient-background) {
  position: relative;
  z-index: 1;
}

.flight-listing-container {
  position: relative;
  min-height: 100vh;
}

.flight-listing-container > *:not(.gradient-background) {
  position: relative;
  z-index: 1;
}
/* .MuiAppBar-root {
  background-color: transparent !important;
} */
